import { gql } from '@/shared/graphql/generated';

export const getEnvironmentAddress = gql(/* GraphQL */ `
  query GetEnvironmentAddress {
    environmentAddress: environmentAddressById(id: 1) {
      keyManagerAddress
      stateChainGatewayAddress
    }
  }
`);

export const getValidatorSCCache = gql(/* GraphQL */ `
  query GetValidatorByIdSs58($validatorId: String!) {
    validators: allValidators(condition: { idSs58: $validatorId }) {
      nodes {
        ...CacheValidator
      }
    }
  }
`);

export const getActiveCacheValidators = gql(/* GraphQL */ `
  query GetActiveCacheValidators {
    validators: allValidators(
      filter: {
        or: [{ isCurrentAuthority: { equalTo: true } }, { isCurrentBackup: { equalTo: true } }]
      }
    ) {
      nodes {
        ...CacheValidator
      }
    }
  }
`);
