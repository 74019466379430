import { gql } from '@/shared/graphql/generated';

export const getLatestBlocksQuery = gql(/* GraphQL */ `
  query GetLatestBlocks($limit: Int! = 10) {
    latestBlocks: allBlocks(first: $limit, orderBy: ID_DESC) {
      nodes {
        ...BlockListBlock
      }
    }
  }
`);

export const getTotalBlockCountQuery = gql(/* GraphQL */ `
  query GetTotalBlockCount {
    latestBlock: allBlocks(orderBy: ID_DESC, first: 1) {
      nodes {
        totalCount: id
      }
    }
  }
`);

export const getAllBlocksQuery = gql(/* GraphQL */ `
  query GetAllBlocks($first: Int!, $offset: Int) {
    allBlocks(orderBy: ID_DESC, offset: $offset, first: $first) {
      pageInfo {
        hasPreviousPage
        startCursor
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...BlockListBlock
        }
      }
      totalCount
    }
  }
`);

export const getBlockByHashQuery = gql(/* GraphQL */ `
  query getBlockByHash($hash: String) {
    blocks: allBlocks(condition: { hash: $hash }, first: 1) {
      nodes {
        ...BlockWithEventsAndExtrinsics
      }
    }
    lastBlocks: allBlocks(last: 1) {
      nodes {
        id
      }
    }
  }
`);

export const getBlockByIdQuery = gql(/* GraphQL */ `
  query getBlockById($id: Int) {
    blocks: allBlocks(condition: { id: $id }, first: 1) {
      nodes {
        ...BlockWithEventsAndExtrinsics
      }
    }
    lastBlocks: allBlocks(last: 1) {
      nodes {
        id
      }
    }
  }
`);
