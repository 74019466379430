import { Link, AgeWithTooltip } from '@/shared/components';
import TableRow from '@/shared/components/TableRow';
import { type BlockListBlockFragment } from '@/shared/graphql/generated/graphql';
import { BoxIcon } from '@/shared/icons/large';
import { formatWithCommas } from '@/shared/utils/numbers';

interface BlockRowProps {
  block: BlockListBlockFragment;
  preview?: boolean;
}

const BlockRow = ({ block, preview }: BlockRowProps) => (
  <TableRow
    className="transition:ease-in h-[64px] font-aeonikMono duration-150 hover:cursor-pointer hover:bg-cf-gray-3"
    href={`/blocks/${block.id}`}
  >
    <td>
      <div className="flex space-x-2">
        <BoxIcon width="24" height="24" className=" text-cf-green-1" />
        <Link href={`/blocks/${block.id}`} underline noPropagate>
          {formatWithCommas(block.id)}
        </Link>
      </div>
    </td>
    <td>{formatWithCommas(block.events.totalCount)}</td>
    <td>{formatWithCommas(block.extrinsics.totalCount)}</td>
    {!preview && (
      <td className="w-[1%] font-aeonikMono">
        {block.validator && (
          <Link underline href={`/validators/${block.validator.account.idSs58}`} noPropagate>
            {block.validator.account.idSs58}
          </Link>
        )}
      </td>
    )}
    <td>
      <div className="flex justify-end whitespace-nowrap text-cf-light-2">
        <AgeWithTooltip timestamp={block.timestamp} />
      </div>
    </td>
  </TableRow>
);

export default BlockRow;
