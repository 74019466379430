import { Link } from '@/shared/components';
import { TableV2 } from '@/shared/components/molecules/TableV2';
import TableRow from '@/shared/components/TableRow';
import { BlockOutsideIcon } from '@/shared/icons/large';
import { formatWithCommas } from '@/shared/utils';
import { useRecentEventsByBlock } from '../../hooks/useRecentEventsByBlock';

const Row = ({
  name,
  info,
}: {
  name: string;
  info: {
    blockId: number;
    indexInBlock: number;
    count: number;
  };
}) => (
  <TableRow
    className="transition:ease-in h-[64px] duration-150 hover:cursor-pointer hover:bg-cf-gray-3"
    href={`/events/${info.blockId}-${info.indexInBlock}`}
  >
    <td className="text-white">{name}</td>
    <td>
      <div className="flex items-center gap-x-1.5 whitespace-nowrap font-aeonikMono">
        <BlockOutsideIcon className="text-cf-blue-2" />
        <Link href={`/events/${info.blockId}-${info.indexInBlock}`} underline noPropagate>
          {`${formatWithCommas(info.blockId)}-${info.indexInBlock}`}
        </Link>
      </div>
    </td>
    <td className="flex justify-end">
      <div className="rounded-md border border-cf-gray-4 bg-cf-gray-3 px-2 py-1">
        <span className="font-aeonikMono text-12 text-cf-light-3">{info.count}x</span>
      </div>
    </td>
  </TableRow>
);

const LatestEventsTable = () => {
  const { recentEvents, isLoading } = useRecentEventsByBlock();

  const rows =
    recentEvents &&
    Object.entries(recentEvents)
      .reverse()
      .flatMap(([blockId, rollup]) =>
        Object.entries(rollup).map(([name, info]) => (
          <Row key={`${blockId}-${name}`} name={name} info={info} />
        )),
      )
      .slice(0, 10);

  return (
    <div className="text-white">
      <div className="my-6 flex items-center">
        <span className="text-20 text-cf-white">Latest Events</span>
      </div>
      <TableV2
        columns={['Event', 'Block Index', { name: 'Times emitted', alignment: 'right' }]}
        isLoading={isLoading}
        rows={rows || []}
      />
    </div>
  );
};

export default LatestEventsTable;
