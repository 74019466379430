import { gql } from '@/shared/graphql/generated';

export const getEventByBlockIdAndIndex = gql(/* GraphQL */ `
  query GetEventsByBlockIdAndIndex($blockId: Int!, $index: Int!) {
    events: allEvents(condition: { blockId: $blockId, indexInBlock: $index }, first: 1) {
      nodes {
        args
        extrinsic: extrinsicByExtrinsicId {
          indexInBlock
        }
        metadata: metadatumByMetadataId {
          name
          chainspec
        }
      }
    }
  }
`);

export const getExtrinsicSuccessMetadataIdQuery = gql(/* GraphQL */ `
  query GetExtrinsicSuccessMetadataId {
    metadata: allMetadata(
      condition: { name: "System.ExtrinsicSuccess" }
      first: 1
      orderBy: ID_DESC
    ) {
      nodes {
        id
      }
    }
  }
`);

export const getRecentEventsByBlockQuery = gql(/* GraphQL */ `
  query GetRecentEvents($excludeMetadataId: Int!, $first: Int!) {
    blocks: allBlocks(first: $first, orderBy: ID_DESC) {
      nodes {
        id
        eventsByBlockId(filter: { metadataId: { notEqualTo: $excludeMetadataId } }) {
          nodes {
            metadata: metadatumByMetadataId {
              name
            }
            blockId
            indexInBlock
          }
        }
      }
    }
  }
`);
