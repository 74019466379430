export default function Clipboard({ ...props }: Record<string, string>): JSX.Element {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.01898 6.75H7.76898C6.66441 6.75 5.76898 7.64543 5.76898 8.75V17.25C5.76898 18.3546 6.66441 19.25 7.76898 19.25H16.269C17.3736 19.25 18.269 18.3546 18.269 17.25V8.75C18.269 7.64543 17.3736 6.75 16.269 6.75H15.019"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.019 8.25H10.019C9.4667 8.25 9.01898 7.80228 9.01898 7.25V5.75C9.01898 5.19772 9.4667 4.75 10.019 4.75H14.019C14.5713 4.75 15.019 5.19772 15.019 5.75V7.25C15.019 7.80228 14.5713 8.25 14.019 8.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.76898 12.25H14.269"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.76898 15.25H14.269"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
