import { useEffect, useState } from 'react';
import { type Token } from '../assets/tokens';
import { type TokenPrice, fetchTokenPrices } from '../utils/fetchTokenPrices';
import { noop } from '../utils/functions';

export const useHistoricalPrice = (token: Token, date: string) => {
  const [price, setPrice] = useState<Record<Token['symbol'], TokenPrice> | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!token || !date) return;
    setIsLoading(true);
    fetchTokenPrices([token], new Date(date))
      .then((prices) => {
        setPrice({ [token.symbol]: prices[0] });
      })
      .catch(noop)
      .finally(() => {
        setIsLoading(false);
      });
  }, [token, date]);

  return {
    historicalPrice: price,
    isLoading,
  };
};
