import React, { createElement, useState } from 'react';
import { abbreviate } from '@chainflip/utils/string';
import classNames from 'classnames';
import ClipboardIcon from '@/shared/assets/icons/Clipboard';
import KeyIcon from '@/shared/assets/icons/Key';
import { Card, Link } from '@/shared/components';
import { type BlockListBlockFragment } from '@/shared/graphql/generated/graphql';
import { useAfterMount } from '@/shared/hooks';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import { ContractSuccessIcon } from '@/shared/icons/large';
import { etherscanUrl } from '@/shared/utils/helpers';
import { formatWithCommas } from '@/shared/utils/numbers';
import BlockIcon from '../../assets/svg/block.svg';
import { getExtrinsicCount } from '../../queries/extrinsics';
import { getEnvironmentAddress } from '../../queries/statechainCache';

type BlinkProps = {
  children: React.ReactNode;
  className?: string;
};

function Blink({ children, className }: BlinkProps): JSX.Element {
  const [state, setState] = useState({
    switching: false,
    preTransitionChild: children,
    child: children,
  });

  useAfterMount(() => {
    setState((previous) => ({ ...previous, switching: true, child: children }));

    const timeout = setTimeout(
      () =>
        setState({
          switching: false,
          preTransitionChild: children,
          child: children,
        }),
      500,
    );

    return () => clearTimeout(timeout);
  }, [children]);

  const switching = state.switching && state.preTransitionChild !== undefined;

  return createElement(
    'div',
    {
      className: classNames(className, 'transition-opacity duration-500', switching && 'opacity-0'),
    },
    switching ? state.preTransitionChild : state.child,
  );
}

interface MetadataProps {
  blockList: BlockListBlockFragment[];
}

export default function MetadataCards({ blockList }: MetadataProps): JSX.Element {
  const { data } = useGqlQuery(getEnvironmentAddress, {
    context: { clientName: 'statechainCache' },
  });
  const { data: countData } = useGqlQuery(getExtrinsicCount, {
    context: { clientName: 'processor' },
    refetchInterval: 5000,
  });
  const { stateChainGatewayAddress, keyManagerAddress } = data?.environmentAddress || {};

  return (
    <div className="flex flex-col gap-x-8 gap-y-4 text-center md:flex-row md:justify-evenly md:text-left [&>*>div]:justify-center [&>*>div]:md:justify-start">
      <Card title="Finalized Block" icon={<BlockIcon />} loading={blockList[0] === undefined}>
        <Blink>{formatWithCommas(blockList[0]?.id)}</Blink>
      </Card>
      <Card
        loading={countData === undefined}
        title="Extrinsic Count"
        icon={<ContractSuccessIcon className="text-cf-red-2" />}
      >
        <Blink>{formatWithCommas(countData?.allExtrinsics?.nodes[0].id ?? 0)}</Blink>
      </Card>
      <Card
        loading={keyManagerAddress === undefined}
        title="Key Manager Address"
        icon={<KeyIcon className="text-cf-blue-4" />}
      >
        <Link
          target="_blank"
          href={`${etherscanUrl()}/address/${keyManagerAddress}`}
          rel="noreferrer"
          underline
          className="font-aeonikMono"
        >
          {abbreviate(keyManagerAddress, 4)}
        </Link>
      </Card>
      <Card
        icon={<ClipboardIcon className="text-cf-orange-3" />}
        loading={stateChainGatewayAddress === undefined}
        title="State Chain Gateway Address"
      >
        <Link
          target="_blank"
          href={`${etherscanUrl()}/address/${stateChainGatewayAddress}`}
          rel="noreferrer"
          underline
          className="font-aeonikMono"
        >
          {abbreviate(stateChainGatewayAddress, 4)}
        </Link>
      </Card>
    </div>
  );
}
