import { gql } from '@/shared/graphql/generated';

export const getProtocolStatsQuery = gql(/* GraphQL */ `
  query GetProtocolStatsQuery($maxTimestamp: Datetime!) {
    twoLegSwaps: allSwaps(
      filter: {
        type: { notIn: [NETWORK_FEE, INGRESS_EGRESS_FEE] }
        intermediateAmount: { isNull: false }
        swapExecutedBlockTimestamp: { isNull: false }
      }
    ) {
      aggregates {
        sum {
          intermediateValueUsd
          swapOutputValueUsd
        }
        distinctCount {
          id
        }
      }
    }
    oneLegSwaps: allSwaps(
      filter: {
        type: { notIn: [NETWORK_FEE, INGRESS_EGRESS_FEE] }
        intermediateAmount: { isNull: true }
        swapExecutedBlockTimestamp: { isNull: false }
      }
    ) {
      aggregates {
        sum {
          swapOutputValueUsd
        }
        distinctCount {
          id
        }
      }
    }
    historicalTwoLegSwaps: allSwaps(
      filter: {
        type: { notIn: [NETWORK_FEE, INGRESS_EGRESS_FEE] }
        intermediateAmount: { isNull: false }
        swapExecutedBlockTimestamp: { lessThanOrEqualTo: $maxTimestamp }
      }
    ) {
      aggregates {
        sum {
          intermediateValueUsd
          swapOutputValueUsd
        }
        distinctCount {
          id
        }
      }
    }
    historicalOneLegSwaps: allSwaps(
      filter: {
        type: { notIn: [NETWORK_FEE, INGRESS_EGRESS_FEE] }
        intermediateAmount: { isNull: true }
        swapExecutedBlockTimestamp: { lessThanOrEqualTo: $maxTimestamp }
      }
    ) {
      aggregates {
        sum {
          swapOutputValueUsd
        }
        distinctCount {
          id
        }
      }
    }
    historicalBurn: allBurns(filter: { timestamp: { lessThanOrEqualTo: $maxTimestamp } }) {
      aggregates {
        sum {
          amount
        }
      }
    }
    currentBurn: allBurns {
      aggregates {
        sum {
          amount
        }
      }
    }
  }
`);

// cache query
export const getCirculatingSupplyQuery = gql(/* GraphQL */ `
  query GetCirculatingSupply {
    circulation: circulationById(id: 1) {
      circulatingSupply
      totalSupply
    }
  }
`);
