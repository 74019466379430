export default function Key({ ...props }: Record<string, string>): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.3103 13.3793C17.9004 13.3793 20 11.2797 20 8.68966C20 6.09963 17.9004 4 15.3103 4C12.7203 4 10.6207 6.09963 10.6207 8.68966C10.6207 9.03186 10.6573 9.3655 10.727 9.68686L4 16.4138V20H7.58621L8.41379 19.1724V17.2414H10.3448L11.7241 15.8621V13.931H13.6552L14.3132 13.273C14.6345 13.3427 14.9682 13.3793 15.3103 13.3793Z"
        stroke="currentColor"
        strokeWidth="1.65517"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 8C16.5 8.27614 16.2761 8.5 16 8.5C15.7239 8.5 15.5 8.27614 15.5 8C15.5 7.72386 15.7239 7.5 16 7.5C16.2761 7.5 16.5 7.72386 16.5 8Z"
        stroke="currentColor"
      />
    </svg>
  );
}
